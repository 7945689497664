define("discourse/plugins/stemaway-resume/discourse/routes/resume", ["exports", "discourse/routes/discourse", "rsvp", "discourse/plugins/stemaway-resume/discourse/models/resume"], function (_exports, _discourse, _rsvp, _resume) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      if (!this.currentUser) {
        return {
          error: "not_logged_in"
        };
      }
      const user = this.modelFor("user");
      const settings = this.siteSettings;
      return (0, _rsvp.all)([_resume.default.buildUserProps(user, settings), _resume.default.list(user.username)]);
    },
    setupController(controller, model) {
      if (model.error) {
        controller.setProperties({
          error: model.error
        });
      }
      const user = model[0];
      const props = model[1];
      document.body.classList.remove("stemaway-resume-sidebar-minimized");
      controller.setProperties({
        user,
        ...props
      });
    },
    actions: {
      refreshRoute() {
        this.refresh();
      }
    }
  });
});