define("discourse/plugins/stemaway-resume/discourse/components/resume-badge", ["exports", "@ember/component", "@ember/object", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "discourse/lib/url"], function (_exports, _component, _object, _iconLibrary, _decorators, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    badgeIcon(badge) {
      return (0, _iconLibrary.convertIconClass)(badge.icon);
    },
    badgeTypeClassName(badgeTypeId) {
      const typeToName = {
        1: "gold",
        2: "silver",
        3: "bronze"
      };
      return `badge-type-${typeToName[badgeTypeId]}`;
    },
    viewBadge() {
      const slug = this.get("badge.slug");
      const id = this.get("badge.id");
      return _url.default.routeTo(`/badges/${id}/${slug}`);
    }
  }, [["method", "badgeIcon", [(0, _decorators.default)("badge")]], ["method", "badgeTypeClassName", [(0, _decorators.default)("badge.type_id")]], ["method", "viewBadge", [_object.action]]]));
});