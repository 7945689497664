define("discourse/plugins/stemaway-resume/discourse/components/resume-topic-card", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _object, _decorators, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    topicState: "topic-visible",
    toggleTopicIcon: "eye",
    canSeeTopic(editMode) {
      const tags = this.topic.tags;
      if (!editMode && tags.includes("resume-hidden")) {
        return false;
      }
      return true;
    },
    topicVisibility() {
      const tags = this.topic.tags;
      if (tags.includes("resume-hidden")) {
        this.set("topicState", "topic-invisible");
        this.set("toggleTopicIcon", "eye-slash");
      } else if (!tags.includes("resume-hidden")) {
        this.set("topicState", "topic-visible");
        this.set("toggleTopicIcon", "eye");
      }
    },
    submitTopicUpdates(editMode) {
      const topicParams = this.topicParams;
      if (!editMode.editMode) {
        if (topicParams) {
          this.updateTopicTags(topicParams);
        }
      }
    },
    toggleTopic() {
      const tags = this.topic.tags;
      const params = {};
      if (this.topicState === "topic-visible") {
        tags.push("resume-hidden");
        params["hidden"] = true;
        params["tags"] = tags;
        this.set("topicState", "topic-invisible");
        this.set("toggleTopicIcon", "eye-slash");
      } else if (this.topicState === "topic-invisible") {
        const updatedTags = tags.filter(t => t !== "resume-hidden");
        params["hidden"] = false;
        params["tags"] = updatedTags;
        this.set("topicState", "topic-visible");
        this.set("toggleTopicIcon", "eye");
      }
      this.set("topicParams", params);
    },
    updateTopicTags(params) {
      (0, _ajax.ajax)(`/t/-/${this.topic.id}`, {
        type: "PUT",
        data: {
          tags: params["tags"]
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "canSeeTopic", [(0, _decorators.default)("editMode")]], ["method", "topicVisibility", [(0, _decorators.on)("init")]], ["method", "submitTopicUpdates", [(0, _decorators.observes)("editMode")]], ["method", "toggleTopic", [_object.action]]]));
});