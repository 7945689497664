define("discourse/plugins/stemaway-resume/discourse/routes/dashboard", ["exports", "discourse/routes/discourse", "discourse/plugins/stemaway-resume/discourse/models/dashboard"], function (_exports, _discourse, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      const user = this.modelFor("user");
      return _dashboard.default.list(user.username);
    },
    setupController(controller, model) {
      controller.setProperties(model);
    }
  });
});