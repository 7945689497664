define("discourse/plugins/stemaway-resume/discourse/components/resume-top-skills-group", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _component, _computed, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    topic: (0, _computed.reads)("model.topic"),
    hideableTopSkills(topSkills) {
      const hiddenTopSkills = this.user.hiddenTopSkills == null ? [] : this.user.hiddenTopSkills.split("|");
      topSkills.forEach(skill => {
        if (hiddenTopSkills.includes(skill.name)) {
          skill.visible = false;
          skill.icon = "eye-slash";
        } else {
          skill.visible = true;
          skill.icon = "eye";
        }
      });
      return topSkills;
    }
  }, [["method", "hideableTopSkills", [(0, _decorators.default)("topSkills")]]]));
});