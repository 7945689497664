define("discourse/plugins/stemaway-resume/discourse/components/resume-sidebar-item", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "li",
    sidebarExpanded: true,
    sidebarState: "resume-sidebar-expanded",
    tooltipText(section) {
      if (section.tooltip) {
        return section.tooltip;
      } else {
        return section.title;
      }
    },
    hasTooltip(section) {
      if (section.tooltip) {
        return true;
      }
      return false;
    },
    displaySection(section) {
      const endorsements = this.get("endorsements");
      const topSkills = this.get("topSkills");
      if (section.topics.length || section.unique_section) {
        // TODO: Improve this logic
        if (section.title === "Endorsements" && endorsements.length === 0) {
          return false;
        }
        if (section.title === "Top Skills" && topSkills?.length < 1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
  }, [["method", "tooltipText", [(0, _decorators.default)("section")]], ["method", "hasTooltip", [(0, _decorators.default)("section")]], ["method", "displaySection", [(0, _decorators.default)("section")]]]));
});