define("discourse/plugins/stemaway-resume/discourse/components/dashboard-project", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "section",
    classNames: ["dashboard-project"],
    alumniStatus(alumni) {
      if (alumni) {
        return {
          status: "Yes",
          icon: "check-circle"
        };
      } else {
        return {
          status: "No",
          icon: "times-circle"
        };
      }
    },
    applicationStatus(groups, project) {
      const internshipGroup = this.siteSettings.dashboard_internship_group;
      const internshipGroupId = parseInt(internshipGroup, 10);
      if (groups.find(group => group.full_name === project.name)) {
        return _I18n.default.t("stemaway_dashboard.application_status.enrolled");
      } else if (groups.find(group => group.id === internshipGroupId)) {
        return _I18n.default.t("stemaway_dashboard.application_status.received");
      } else {
        return _I18n.default.t("stemaway_dashboard.application_status.pending");
      }
    }
  }, [["method", "alumniStatus", [(0, _decorators.default)("details.alumni")]], ["method", "applicationStatus", [(0, _decorators.default)("groups", "project")]]]));
});