define("discourse/plugins/stemaway-resume/discourse/components/resume-endorsement", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    contents(topicCooked) {
      if (!topicCooked.includes("<p>")) {
        return topicCooked;
      }
      // Otherwise return the first line only
      return topicCooked.split("<p>")[1];
    },
    avatarSrc(avatarTemplate) {
      return avatarTemplate.replace("{size}", "75");
    }
  }, [["method", "contents", [(0, _decorators.default)("endorsement.topic_cooked")]], ["method", "avatarSrc", [(0, _decorators.default)("endorsement.poster.avatar_template")]]]));
});