define("discourse/plugins/stemaway-resume/discourse/controllers/dashboard", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    selectProject(attrs) {
      if (this.activeProject) {
        this.set("activeProject", null);
        this.set("selectedProject", null);
      } else {
        this.set("activeProject", attrs.id);
        this.set("selectedProject", attrs);
      }
    }
  }, [["method", "selectProject", [_object.action]]]));
});