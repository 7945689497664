define("discourse/plugins/stemaway-resume/discourse/controllers/dashboard-skill-details", ["exports", "@ember/controller", "discourse-common/utils/decorators", "discourse/mixins/modal-functionality"], function (_exports, _controller, _decorators, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    modalTitle(name) {
      return `${name} Verified By:`;
    },
    actions: {
      save() {
        this.send("closeModal");
      },
      resetToDefault() {
        this.get("model.reset")();
      }
    }
  }, [["method", "modalTitle", [(0, _decorators.default)("model.skill.name")]]]));
});