define("discourse/plugins/stemaway-resume/discourse/components/resume-user-card", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object", "@ember/runloop", "discourse/lib/url"], function (_exports, _component, _decorators, _object, _runloop, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "section",
    classNames: ["resume-user-card"],
    elementId: "resume-section-0",
    bannerImage(user) {
      const defaultBannerImage = "/plugins/stemaway-resume/images/resume/default-banner.png";
      if (user) {
        if (user.banner) {
          return user.banner;
        } else {
          return defaultBannerImage;
        }
      } else {
        return defaultBannerImage;
      }
    },
    resumeName(user) {
      if (user) {
        if (user.name) {
          return user.name;
        } else {
          return user.username;
        }
      }
    },
    editAbout() {
      let post = this.get("about");
      _url.default.routeTo(`/t/${post.topic_id}`);
      (0, _runloop.next)(() => {
        setTimeout(() => {
          let editButton = document.querySelector("button.widget-button.btn-flat.edit.no-text.btn-icon");
          if (editButton) {
            editButton.click();
          }
          window.history.back();
        }, 700);
      });
    },
    createAbout() {
      const user = this.get("user");
      const name = user.name ? user.name : user.username;
      const bio = "Insert%20content";
      const route = `/new-topic?title=About%20${name}&body=${bio}&category=resume/1-click-resume/about&tags=resume_a`;
      _url.default.routeTo(route);
    }
  }, [["method", "bannerImage", [(0, _decorators.default)("user")]], ["method", "resumeName", [(0, _decorators.default)("user")]], ["method", "editAbout", [_object.action]], ["method", "createAbout", [_object.action]]]));
});