define("discourse/plugins/stemaway-resume/discourse/templates/components/resume-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    class="btn-default"
    icon="id-card"
    action=(action "viewResume")
    translatedLabel=(i18n "stemaway_resume.user_card.label")
  }}
  */
  {
    "id": "5w7O93B0",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"icon\",\"action\",\"translatedLabel\"],[\"btn-default\",\"id-card\",[28,[37,1],[[30,0],\"viewResume\"],null],[28,[37,2],[\"stemaway_resume.user_card.label\"],null]]]]]],[],false,[\"d-button\",\"action\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-resume/discourse/templates/components/resume-button.hbs",
    "isStrictMode": false
  });
});