define("discourse/plugins/stemaway-resume/discourse/models/dashboard", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Dashboard = _object.default.extend({});
  Dashboard.reopenClass({
    list(username) {
      return (0, _ajax.ajax)(`/u/${username}/dashboard.json`).then(response => response?.dashboard || {}).catch(error => {
        console.error("Failed to fetch dashboard:", error);
        return {};
      });
    }
  });
  var _default = _exports.default = Dashboard;
});