define("discourse/plugins/stemaway-resume/discourse/components/stemaway-profile-button", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    canViewProfile(username, restricted) {
      if (restricted) {
        if (!this.currentUser) {
          return false;
        } else if (this.currentUser.username === username) {
          return true;
        } else if (this.currentUser.admin) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }, [["method", "canViewProfile", [(0, _decorators.default)("model.username", "restricted")]]]));
});