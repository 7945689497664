define("discourse/plugins/stemaway-resume/discourse/components/resume-top-skill-item", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _component, _object, _decorators, _ajax, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "li",
    classNames: ["resume-top-skill"],
    visibility() {
      if (this.skill.visible) {
        this.set("visibilityIcon", "eye");
      } else {
        this.set("visibilityIcon", "eye-slash");
      }
    },
    isVerified(skill) {
      let verified = "";
      if (skill.postVerified || skill.mentorVerified || skill.challengeVerified) {
        verified = "dashboard-project-skill-verified";
      } else {
        verified = "";
      }
      if (skill.parent) {
        verified = `${verified} parent`;
      }
      return verified;
    },
    toggleTopSkill(skill) {
      // get which field to modify
      const hiddenTopSkillsFieldId = this.siteSettings.resume_hidden_top_skills_field_id;
      const allSkills = this.get("allSkills");
      const currentHiddenTopSkills = allSkills.filter(s => !s.visible);
      const skillAlreadyHidden = currentHiddenTopSkills.find(s => s.name === skill.name);
      let finalPropertyValues;
      if (skillAlreadyHidden) {
        // unhide the skill
        const newHiddenTopSkills = currentHiddenTopSkills.filter(s => s.name !== skill.name);
        finalPropertyValues = newHiddenTopSkills.map(obj => obj["name"]).join("|");
        this.set("visibilityIcon", "eye");
      } else {
        finalPropertyValues = `${currentHiddenTopSkills.map(obj => obj["name"]).join("|")}|${skill.name}`;
        this.set("visibilityIcon", "eye-slash");
      }
      const data = {};
      data["user_fields"] = {
        [hiddenTopSkillsFieldId]: finalPropertyValues
      };
      (0, _ajax.ajax)(`/u/${this.user.username}.json`, {
        type: "PUT",
        data
      }).then(() => window.location.reload()).catch(_ajaxError.popupAjaxError);
    },
    showDashboardSkillDetailsModal() {
      if (!this.get("isVerified").includes("dashboard-project-skill-verified")) {
        return;
      }
      return (0, _showModal.default)("dashboardSkillDetails", {
        model: {
          user: this.get("user"),
          skill: this.get("skill")
        }
      });
    }
  }, [["method", "visibility", [(0, _decorators.on)("init")]], ["method", "isVerified", [(0, _decorators.default)("skill")]], ["method", "toggleTopSkill", [_object.action]], ["method", "showDashboardSkillDetailsModal", [_object.action]]]));
});