define("discourse/plugins/stemaway-resume/discourse/initializers/user-card-resume-link", ["exports", "discourse/lib/plugin-api", "discourse/lib/url"], function (_exports, _pluginApi, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    api.modifyClass("route:about", {
      pluginId: "stemaway-resume",
      model() {
        if (this.currentUser && this.currentUser.admin) {
          this._super();
        } else {
          this.replaceWith("discovery.latest");
        }
      }
    });
    api.modifyClass("route:users", {
      pluginId: "stemaway-resume",
      beforeModel() {
        if (this.currentUser && this.currentUser.admin) {
          this._super();
        } else {
          this.replaceWith("discovery.latest");
        }
      }
    });
    api.modifyClass("controller:user-card", {
      pluginId: "stemaway-resume",
      actions: {
        showUser(user) {
          _url.default.routeTo(`/u/${user.username_lower}/resume`);
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "debaleena-discourse.js",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializePlugin);
    }
  };
});