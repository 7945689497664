define("discourse/plugins/stemaway-resume/discourse/components/dashboard-project-skill", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/show-modal"], function (_exports, _component, _object, _decorators, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "li",
    isVerified(skill) {
      if (skill.postVerified || skill.mentorVerified || skill.challengeVerified) {
        return "dashboard-project-skill-verified";
      } else {
        return "";
      }
    },
    didInsertElement() {
      this._super(...arguments);
    },
    showDashboardSkillDetailsModal() {
      if (!this.get("isVerified")) {
        return;
      }
      return (0, _showModal.default)("dashboardSkillDetails", {
        model: {
          user: this.get("user"),
          skill: this.get("skill")
        }
      });
    }
  }, [["method", "isVerified", [(0, _decorators.default)("skill")]], ["method", "showDashboardSkillDetailsModal", [_object.action]]]));
});