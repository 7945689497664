define("discourse/plugins/stemaway-resume/discourse/templates/components/resume-access-error", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n title}}</h1>
  <p>{{i18n message}}</p>
  <img src="/plugins/stemaway-resume/images/resume/hidden-resume.svg" alt="hidden resume illustration">
  */
  {
    "id": "LBC9lG4i",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,1],[[30,0,[\"title\"]]],null]],[13],[1,\"\\n\"],[10,2],[12],[1,[28,[35,1],[[30,0,[\"message\"]]],null]],[13],[1,\"\\n\"],[10,\"img\"],[14,\"src\",\"/plugins/stemaway-resume/images/resume/hidden-resume.svg\"],[14,\"alt\",\"hidden resume illustration\"],[12],[13],[1,[28,[32,0],[\"[[\\\"The `title` property path was used in the `discourse/plugins/stemaway-resume/discourse/templates/components/resume-access-error.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.title}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `message` property path was used in the `discourse/plugins/stemaway-resume/discourse/templates/components/resume-access-error.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.message}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"h1\",\"i18n\",\"p\",\"img\"]]",
    "moduleName": "discourse/plugins/stemaway-resume/discourse/templates/components/resume-access-error.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});