define("discourse/plugins/stemaway-resume/discourse/templates/connectors/user-main-nav/resume-tab", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{stemaway-profile-button
    model=model
    icon="address-card"
    label=(i18n "stemaway_resume.user_main_nav.resume_title")
    link="resume"
    classes="nav-resume"
    restricted=false
  }}
  
  {{stemaway-profile-button
    model=model
    icon="tachometer-alt"
    label=(i18n "stemaway_resume.user_main_nav.dashboard_title")
    link="dashboard"
    classes="nav-dashboard"
    restricted=false
  }}
  */
  {
    "id": "p8D1PzJq",
    "block": "[[[1,[28,[35,0],null,[[\"model\",\"icon\",\"label\",\"link\",\"classes\",\"restricted\"],[[30,0,[\"model\"]],\"address-card\",[28,[37,1],[\"stemaway_resume.user_main_nav.resume_title\"],null],\"resume\",\"nav-resume\",false]]]],[1,\"\\n\\n\"],[1,[28,[35,0],null,[[\"model\",\"icon\",\"label\",\"link\",\"classes\",\"restricted\"],[[30,0,[\"model\"]],\"tachometer-alt\",[28,[37,1],[\"stemaway_resume.user_main_nav.dashboard_title\"],null],\"dashboard\",\"nav-dashboard\",false]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/stemaway-resume/discourse/templates/connectors/user-main-nav/resume-tab.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/stemaway-resume/discourse/templates/connectors/user-main-nav/resume-tab.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"stemaway-profile-button\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-resume/discourse/templates/connectors/user-main-nav/resume-tab.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});