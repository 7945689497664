define("discourse/plugins/stemaway-resume/discourse/controllers/share-popup", ["exports", "@ember/controller", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/lib/sharing", "discourse/mixins/modal-functionality"], function (_exports, _controller, _getUrl, _decorators, _sharing, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    resumeUrl(user) {
      const path = `/u/${user.username}/resume`;
      return user ? (0, _getUrl.getAbsoluteURL)(path) : null;
    },
    resumeTitle(user) {
      return `${user.name}'s Resume`;
    },
    sources() {
      return _sharing.default.activeSources(this.siteSettings.share_links);
    },
    actions: {
      save() {
        this.send("closeModal");
      },
      share(source) {
        const url = this.resumeUrl;
        const title = this.resumeTitle;
        _sharing.default.shareSource(source, {
          url: url,
          title: title
        });
      }
    }
  }, [["method", "resumeUrl", [(0, _decorators.default)("model.user")]], ["method", "resumeTitle", [(0, _decorators.default)("model.user")]], ["method", "sources", [_decorators.default]]]));
});