define("discourse/plugins/stemaway-resume/discourse/connectors/topic-footer-main-buttons-before-create/mentor-verified-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api"], function (_exports, _ajax, _ajaxError, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const curCategory = args.topic.category_id;
      const settings = component.siteSettings;
      const verifiedSkillsCategory = Number(settings.dashboard_verified_skills_category);
      const verifyGroup = Number(settings.dashboard_can_mentor_verify_groups);
      const currentUserGroups = (0, _pluginApi.withPluginApi)("1.2.0", api => {
        return api.getCurrentUser().groups.map(g => g.id);
      });
      if (curCategory === verifiedSkillsCategory && currentUserGroups.includes(verifyGroup)) {
        return true;
      } else {
        return false;
      }
    },
    setupComponent(attrs, component) {
      if (attrs.topic.tags.includes("mentor-verified")) {
        component.setProperties({
          topicId: attrs.topic.id,
          topicTags: attrs.topic.tags,
          state: "verified",
          icon: "times",
          label: "stemaway_dashboard.mentor_verified_button.unverify",
          classes: "btn btn-default btn-mentor-unverify"
        });
      } else {
        component.setProperties({
          topicId: attrs.topic.id,
          topicTags: attrs.topic.tags,
          state: "unverified",
          icon: "check",
          label: "stemaway_dashboard.mentor_verified_button.verify",
          classes: "btn btn-default btn-mentor-verify"
        });
      }
    },
    actions: {
      handleMentorVerification() {
        const topicId = this.get("topicId");
        const topicTags = this.get("topicTags");
        if (this.state === "verified") {
          topicTags.pop("mentor-verified");
          (0, _ajax.ajax)(`/t/-/${topicId}`, {
            type: "PUT",
            data: {
              tags: topicTags
            }
          }).catch(_ajaxError.popupAjaxError);
          this.setProperties({
            state: "unverified",
            icon: "check",
            label: "stemaway_dashboard.mentor_verified_button.verify",
            classes: "btn btn-default btn-mentor-verify"
          });
        } else {
          topicTags.push("mentor-verified");
          (0, _ajax.ajax)(`/t/-/${topicId}`, {
            type: "PUT",
            data: {
              tags: topicTags
            }
          }).catch(_ajaxError.popupAjaxError);
          this.setProperties({
            state: "verified",
            icon: "times",
            label: "stemaway_dashboard.mentor_verified_button.unverify",
            classes: "btn btn-default btn-mentor-unverify"
          });
        }
      }
    }
  };
});