define("discourse/plugins/stemaway-resume/discourse/models/resume", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Resume = _object.default.extend({});
  Resume.reopenClass({
    list(username) {
      return (0, _ajax.ajax)(`/u/${username}/resume.json`).then(response => response?.resume || {}).catch(error => {
        console.error("Failed to fetch resume:", error);
        return {};
      });
    },
    buildUserProps(user, settings) {
      const stemLevelField = settings.resume_stem_level_field;
      const interestsField = settings.resume_interests_field;
      const statusField = settings.resume_status_field;
      const hiddenTopSkillsField = settings.resume_hidden_top_skills_field_id;
      const title = user.title ? user.title : user.user_fields[stemLevelField];
      const interests = user.user_fields[interestsField] ? user.user_fields[interestsField].split(",") : null;
      const status = user.user_fields[statusField] ? user.user_fields[statusField] : null;
      const hiddenTopSkills = user.user_fields[hiddenTopSkillsField];
      const props = {
        id: user.id,
        avatar: user.avatar_template.replace("{size}", "75"),
        bio: user.bio_cooked,
        featured_topic: user.featured_topic,
        name: user.name,
        username: user.username,
        profile_path: user.path,
        resume_path: `${user.path}/resume`,
        banner: user.profile_background_upload_url,
        title,
        interests,
        status,
        hiddenTopSkills,
        badges: user.badges
      };
      return props;
    }
  });
  var _default = _exports.default = Resume;
});