define("discourse/plugins/stemaway-resume/discourse/templates/connectors/user-card-before-badges/stemaway-profile-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{resume-button username=this.user.username}}
  */
  {
    "id": "o08epBWF",
    "block": "[[[1,[28,[35,0],null,[[\"username\"],[[30,0,[\"user\",\"username\"]]]]]]],[],false,[\"resume-button\"]]",
    "moduleName": "discourse/plugins/stemaway-resume/discourse/templates/connectors/user-card-before-badges/stemaway-profile-link.hbs",
    "isStrictMode": false
  });
});